import request from '../util/request';
export function login(query) {
  return request({
    url: 'v2/front/login',
    method: 'post',
    data: {
			username: query.email,
			password: query.password
		},
  });
}
export function getDataCalon(params) {
  return request({
    url: 'v2/front/getDataCalon',
    method: 'get',
    params: params,
  });
}

export function getJabatanLokasi() {
  return request({
    url: 'v2/front/getJabatanLokasi',
    method: 'get',
  });
}

export function registrasiJPT(data) {
  return request({
    url: 'v2/front/registrasiJPT',
    method: 'post',
    data: data
  });
}


export function viewDokumen(data) {
  return request({
    url: 'v2/front/getDownload',
    method: 'get',
    params: data,
    responseType: 'blob'
  });
}