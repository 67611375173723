const router = {
	path: "/",
	component: () => import(/* webpackChunkName: "user" */ "../views/Umum"),
	redirect: "/login",
	children: [
		{
			path: "login",
			component: () => import(/* webpackChunkName: "user" */ "../views/Umum/Login"),
      name: "Login"
		}
	],
}

export default router