import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
import Umum from './umum';
import Admin from './admin'
import Peserta from './peserta'
import Login from './login'
const routes = [
  Umum,
  Admin,
  Peserta,
  Login,
  {path: '*', component: NotFound}
];

export default routes
