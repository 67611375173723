import DashboardLayout from '../views/Layout/DashboardLayout.vue'
const router = {
	path: "/admin",
	component: DashboardLayout,
  redirect: '/admin/peserta',
	meta: { loginRequired: true },
	/*
	define with Authorization :
	meta: { loginRequired: true, roles: [UserRole.Admin, UserRole.Editor] },
	*/
	children: [
		{
			path: `peserta`,
			component: () => import("../views/Admin/Index"),
			meta: { roles: [ process.env.VUE_APP_ADMIN] },
			children: [
				{
					path: "",
					component: () => import("../views/Admin/Peserta/List"),
					name: "PesertaAdmin"
				},
        {
					path: "listDokumen/:id",
					component: () => import("../views/Admin/Peserta/ListDokumen"),
					name: "ListDokumenPeserta"
				},
			]
		}, 

	]
}

export default router