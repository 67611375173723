var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('side-bar',{attrs:{"active-color":_vm.activeColor,"background-color":_vm.backgroundColor,"type":"sidebar"},scopedSlots:_vm._u([{key:"links",fn:function(props){return [(_vm.currentUser.roles == _vm.admin)?_c('sidebar-item',{attrs:{"link":{
          name: 'Peserta',
          icon: 'nc-icon nc-badge',
          path: '/admin/peserta',
        }}}):_vm._e(),(_vm.currentUser.roles == _vm.peserta)?_c('sidebar-item',{attrs:{"link":{
          name: 'Upload Dokumen',
          icon: 'nc-icon nc-badge',
          path: '/peserta/upload_dokumen',
        }}}):_vm._e()]}}])}),_c('sidebar-share',{attrs:{"background-color":_vm.backgroundColor,"active-color":_vm.activeColor},on:{"update:backgroundColor":function($event){_vm.backgroundColor=$event},"update:background-color":function($event){_vm.backgroundColor=$event},"update:activeColor":function($event){_vm.activeColor=$event},"update:active-color":function($event){_vm.activeColor=$event}}}),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('dashboard-content',{nativeOn:{"click":function($event){return _vm.toggleSidebar.apply(null, arguments)}}}),_c('content-footer')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }