import DashboardLayout from '../views/Layout/DashboardLayout.vue'
const router = {
	path: "/peserta",
	component: DashboardLayout,
  redirect: '/peserta/upload_dokumen',
	meta: { loginRequired: true },
	/*
	define with Authorization :
	meta: { loginRequired: true, roles: [UserRole.Admin, UserRole.Editor] },
	*/
	children: [
		{
			path: `upload_dokumen`,
			component: () => import("../views/Peserta/Index"),
			meta: { roles: [ process.env.VUE_APP_PESERTA] },
			children: [
				{
					path: "",
					component: () => import("../views/Peserta/UploadDokumen/List"),
					name: "UploadDokumen"
				},
			]
		}, 

	]
}

export default router