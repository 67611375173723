const router = {

  path: "/",
	component: () => import(/* webpackChunkName: "user" */ "../views/Portal/Layout"),
	redirect: "/home",
	children: [
		{
			path: "home",
			component: () => import(/* webpackChunkName: "user" */ "../views/Portal/Home"),
      name: "Home"
		},
    {
			path: "pengumuman",
			component: () => import(/* webpackChunkName: "user" */ "../views/Portal/Pengumuman"),
      name: "Pengumuman"
		},
    {
			path: "persyaratan",
			component: () => import(/* webpackChunkName: "user" */ "../views/Portal/Persyaratan"),
      name: "Persyaratan"
		},
    {
			path: "dokumen_persyaratan",
			component: () => import(/* webpackChunkName: "user" */ "../views/Portal/DokumenPersyaratan"),
      name: "DokumenPersyaratan"
		},
    {
			path: "alur",
			component: () => import(/* webpackChunkName: "user" */ "../views/Portal/Alur"),
      name: "AlurSeleksi"
		},
    {
			path: "jadwal",
			component: () => import(/* webpackChunkName: "user" */ "../views/Portal/Jadwal"),
      name: "JadwalSeleksi"
		},
	]
}

export default router