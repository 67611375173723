var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('side-bar',{attrs:{"active-color":_vm.activeColor,"background-color":_vm.backgroundColor,"type":"sidebar"},scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'nc-icon nc-bank',
          path: '/admin/peserta',
        }}}),(_vm.roles)?_c('sidebar-item',{staticClass:"example",attrs:{"opened":"","link":{ name: 'Examples (API)', icon: 'fab fa-vuejs fa-2x' }}},[(_vm.roles.includes('admin'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Role Management',
            path: '/examples/role-management/list-roles',
          }}}):_vm._e(),(_vm.roles.includes('admin'))?_c('sidebar-item',{attrs:{"link":{
            name: 'User Management',
            path: '/examples/user-management/list-users',
          }}}):_vm._e(),(_vm.roles.includes('admin') || _vm.roles.includes('creator'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Category Management',
            path: '/examples/category-management/list-categories',
          }}}):_vm._e(),(_vm.roles.includes('admin') || _vm.roles.includes('creator'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Tag Management',
            path: '/examples/tag-management/list-tags',
          }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
            name: 'Item Management',
            path: '/examples/item-management/list-items',
          }}})],1):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Components',
          icon: 'nc-icon nc-layout-11',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Buttons',
            path: '/components/buttons',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Grid System',
            path: '/components/grid-system',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Panels',
            path: '/components/panels',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Sweet Alert',
            path: '/components/sweet-alert',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Notifications',
            path: '/components/notifications',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Icons',
            path: '/components/icons',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Typography',
            path: '/components/typography',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Forms',
          icon: 'nc-icon nc-ruler-pencil',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Regular Forms',
            path: '/forms/regular',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Extended Forms',
            path: '/forms/extended',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Validation Forms',
            path: '/forms/validation',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Wizard',
            path: '/forms/wizard',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Table List',
          icon: 'nc-icon nc-single-copy-04',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Regular Tables',
            path: '/table-list/regular',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Extended Tables',
            path: '/table-list/extended',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Paginated Tables',
            path: '/table-list/paginated',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Maps',
          icon: 'nc-icon nc-pin-3',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Google Maps',
            path: '/maps/google',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Full Screen Maps',
            path: '/maps/full-screen',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Widgets',
          icon: 'nc-icon nc-box',
          path: '/admin/widgets',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Charts',
          icon: 'nc-icon nc-chart-bar-32',
          path: '/charts',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Calendar',
          icon: 'nc-icon nc-calendar-60',
          path: '/calendar',
        }}})]}}])},[_c('user-menu')],1),_c('sidebar-share',{attrs:{"background-color":_vm.backgroundColor,"active-color":_vm.activeColor},on:{"update:backgroundColor":function($event){_vm.backgroundColor=$event},"update:background-color":function($event){_vm.backgroundColor=$event},"update:activeColor":function($event){_vm.activeColor=$event},"update:active-color":function($event){_vm.activeColor=$event}}}),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('dashboard-content',{nativeOn:{"click":function($event){return _vm.toggleSidebar.apply(null, arguments)}}}),_c('content-footer')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }