import Vue from "vue";
import router from "@/main";
import { VueAuthenticate } from "vue-authenticate";

import axios from "axios";
import { login } from "../../api/user"
import VueAxios from "vue-axios";
import { setCurrentUser, getCurrentUser } from '../../util'
Vue.use(VueAxios, axios);


export default {
  state: {
    currentUser: getCurrentUser(),
    loginError: null,
    processing: false
  },

  getters: {
    currentUser: state => state.currentUser,
    processing: state => state.processing,
    loginError: state => state.loginError,
  },

  mutations: {
    setUser(state, payload) {
      state.currentUser = payload
      state.processing = false
      state.loginError = null
    },
    setLogout(state) {
      state.currentUser = null
      state.processing = false
      state.loginError = null
    },
    setProcessing(state, payload) {
      state.processing = payload
      state.loginError = null
    },
    setError(state, payload) {
      state.loginError = payload
      state.currentUser = null
      state.processing = false
    },
    clearError(state) {
      state.loginError = null
    },
    setLogout(state) {
      state.currentUser = null
      state.processing = false
      state.loginError = null
    },
  },

  actions: {
    login({ commit }, payload) {
      commit('clearError')
      commit('setProcessing', true)
			return new Promise((resolve, reject) => {
				login(payload.user)
					.then(response => {
            // console.log(response)
						setCurrentUser(response.data)
						commit('setUser', response.data)
            commit('setProcessing', false)
						resolve()
					})
					.catch(error => {
						setCurrentUser(null);
						commit('setError', error.response.data.error)
            setTimeout(() => {
              commit('clearError')
            }, 3000)
            resolve();
					});
			});
    },
    register(context, payload) {
      return vueAuth.register(payload.user, payload.requestOptions).then(response => {
        context.commit("isAuthenticated", {
          isAuthenticated: vueAuth.isAuthenticated()
        });
        router.push({path: "/"});
      });
    },
    logout({ commit }) {
      setCurrentUser(null);
      commit('setLogout');
      router.push({name: "Login"});
    }
  }
};
