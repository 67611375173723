<template>
  <div class="wrapper">
    <side-bar
      :active-color="activeColor"
      :background-color="backgroundColor"
      type="sidebar"
    >
      <template slot-scope="props" slot="links">
        <sidebar-item v-if="currentUser.roles == admin"
          :link="{
            name: 'Peserta',
            icon: 'nc-icon nc-badge',
            path: '/admin/peserta',
          }"
        />

        <sidebar-item v-if="currentUser.roles == peserta"
          :link="{
            name: 'Upload Dokumen',
            icon: 'nc-icon nc-badge',
            path: '/peserta/upload_dokumen',
          }"
        />
        
        
      </template>
    </side-bar>
    <sidebar-share
      :background-color.sync="backgroundColor"
      :active-color.sync="activeColor"
    >
    </sidebar-share>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
// import UserMenu from "./SidebarPlugin/UserMenu.vue";
import SidebarItem from "./SidebarPlugin/SidebarItem.vue";
import SidebarShare from "./SidebarSharePlugin";
import { mapGetters } from "vuex";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    SidebarItem,
    SidebarShare,
  },
  data() {
    return {
      roles: [],
      backgroundColor: "black",
      activeColor: "success",
      admin: process.env.VUE_APP_ADMIN,
      peserta: process.env.VUE_APP_PESERTA
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
