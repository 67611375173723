import request from "../../util/request";

export default {
  state: {
    percentDokumen : 0,
    modalShow: false
  },
  getters: {
    percentDokumen: state => state.percentDokumen,
    modalShow: state => state.modalShow,
  },
  mutations: {
   
    SET_PERCENT(state, payload){
      state.percentDokumen = payload
    },
    SET_MODAL(state, payload){
      state.modalShow = payload;
    },
  },
  actions: {
    uploadDokumen({ commit, dispatch}, payload){
      return new Promise((resolve) => {
        // commit('SET_LOAD_FORM', true);
        commit('SET_MODAL', true)
        request({
          url: "auth/uploadDokumen",
          method: "post",
          data : payload,
          onUploadProgress: function(uploadEvent){ 
            const {loaded, total} = uploadEvent;
            let percent = Math.floor( loaded * 100 / total);
          
            commit('SET_PERCENT', percent);
          }
        }).then(response => {
          commit('SET_MODAL', false)
          commit('SET_PERCENT', 0);
          resolve(response);
        }).catch( e => {
          commit('SET_MODAL', false)
          commit('SET_PERCENT', 0);
        });  
      })
    },

  
  },
  
};
