import { getCurrentUser } from '.'
import axios from 'axios'

const service = axios.create({
  // baseURL: process.env.MIX_BASE_API,
  baseURL: process.env.VUE_APP_BASE_URL + '/api',
  timeout: 1000 * 60 * 12, // Request timeout,
	maxBodyLength: Infinity,
	maxContentLength: Infinity,
});

service.interceptors.request.use(
  config => {
    const user = getCurrentUser();
		if (user) {
		config.headers['Authorization'] = 'Bearer ' + user.token; // Set JWT token
		}
    return config;
  },
  error => {
    // Do something with request error
    // console.log(error); // for debug
    Promise.reject(error);
  }
);

// response pre-processing
service.interceptors.response.use(
  response => {
    // if (response.headers.authorization) {
    //   setLogged(response.headers.authorization);
    //   response.data.token = response.headers.authorization;
    // }

    return response.data;
  },
  error => {
	
    let message = error.message;
    if(error.response){
      
      if (error.response.data && error.response.data.errors) {
        message = error.response.data.errors;
      } else if (error.response.data && error.response.data.error) {
        message = error.response.data.error;
      }
      // console.log(message)
    }
   
    // Message({
    //   message: message,
    //   type: 'error',
    //   duration: 5 * 1000,
    // });
    return Promise.reject(error);
  }
);

export default service;
